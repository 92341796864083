import React from 'react'

import { useTranslation } from 'gatsby-plugin-react-i18next'

import SEO from 'src/components/seo/index'

import styles from './index.module.scss'

const NotFoundPage = () => {
	const { t } = useTranslation()

	return (
		<div>
			<SEO title={t(`meta.title`)} />

			<div className={styles.notFound}></div>
		</div>
	)
}

export const query = graphql`
	query($language: String!) {
		locales: allLocale(filter: { ns: { in: ["common", "404"] }, language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`

export default NotFoundPage
